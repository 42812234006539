/*eslint-disable */
import React, { Component, useState } from 'react'
import Packery from "react-packery"
import { Modal, ModalHeader } from 'reactstrap'
import TagManager from 'react-gtm-module'

/* COMPONENTS */
import Menu from "./Menu";
import Oferta from "./Oferta"
import SectionWhatsapp from '../../components/SectionWhatsapp'

/* STYLES */
import "./MenuFiltro.css"

/* IMAGES */
import imgAnterior from '../../assets/icons/mobile/next.png'
import modalFavorito from '../../assets/images/modalFavorito.jpg'

function offset (element) {
  let pegaCoordenadas = element.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return { top: pegaCoordenadas.top + scrollTop, left: pegaCoordenadas.left + scrollLeft }
}

const MasonryOptions = {
  percentPosition: true,
  originLeft: true,
}

export default class Aplicativo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ofertas: [],
      categoria: "Todos",
      favoritos: [],
      clicado: false,
      site: this.props.site,
      mostraModal: false,
      mostrouModal: false
    }
    this.notificadoCompleto = false;
    this.notificaCategoria = this.notificaCategoria.bind(this)
    this.notificaFavorito = this.notificaFavorito.bind(this)
    this.carregaOfertas = this.carregaOfertas.bind(this)
    this.popupFavorito = this.popupFavorito.bind(this)
  }

  componentDidMount () {
    this.setState({
      ofertas: this.carregaOfertas()
    })
    document.addEventListener('scroll', this.scrollBottom)
  }
  
  componentWillUnmount() {
    document.removeEventListener('scroll',this.scrollBottom);
  }

  setLocalStorage() {
    if (typeof window !== 'undefined'){
      Object.defineProperty(window, "localStorage", new (function () {
        var aKeys = [], oStorage = {};
        Object.defineProperty(oStorage, "getItem", {
          value: function (sKey) { return sKey ? this[sKey] : null; },
          writable: false,
          configurable: false,
          enumerable: false
        });
        Object.defineProperty(oStorage, "key", {
          value: function (nKeyId) { return aKeys[nKeyId]; },
          writable: false,
          configurable: false,
          enumerable: false
        });
        Object.defineProperty(oStorage, "setItem", {
          value: function (sKey, sValue) {
            if (!sKey) { return; }
            document.cookie = escape(sKey) + "=" + escape(sValue) + "; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/";
          },
          writable: false,
          configurable: false,
          enumerable: false
        });
        Object.defineProperty(oStorage, "length", {
          get: function () { return aKeys.length; },
          configurable: false,
          enumerable: false
        });
        Object.defineProperty(oStorage, "removeItem", {
          value: function (sKey) {
            if (!sKey) { return; }
            document.cookie = escape(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
          },
          writable: false,
          configurable: false,
          enumerable: false
        });
        Object.defineProperty(oStorage, "clear", {
          value: function () {
            if (!aKeys.length) { return; }
            for (var sKey in aKeys) {
              document.cookie = escape(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            }
          },
          writable: false,
          configurable: false,
          enumerable: false
        });
        this.get = function () {
          var iThisIndx;
          for (var sKey in oStorage) {
            iThisIndx = aKeys.indexOf(sKey);
            if (iThisIndx === -1) { oStorage.setItem(sKey, oStorage[sKey]); }
            else { aKeys.splice(iThisIndx, 1); }
            delete oStorage[sKey];
          }
          for (aKeys; aKeys.length > 0; aKeys.splice(0, 1)) { oStorage.removeItem(aKeys[0]); }
          for (var aCouple, iKey, nIdx = 0, aCouples = document.cookie.split(/\s*;\s*/); nIdx < aCouples.length; nIdx++) {
            aCouple = aCouples[nIdx].split(/\s*=\s*/);
            if (aCouple.length > 1) {
              oStorage[iKey = unescape(aCouple[0])] = unescape(aCouple[1]);
              aKeys.push(iKey);
            }
          }
          return oStorage;
        };
        this.configurable = false;
        this.enumerable = true;
      })());
    }
  }

  carregaOfertas() {
    if (!window.localStorage) {
      this.setLocalStorage()
    }
    let favoritos = JSON.parse(window.localStorage.getItem('favoritos') || "[]")
    let baseTamanho = this.calculaBaseTamanho()
    let a = this.props.ofertas.map(o => Object.assign(o, console.log(o), {
      like: favoritos.findIndex(f => f == o.id) > -1,
      style: {
        width: [2, 3].includes(o.format) ? baseTamanho * 2 : baseTamanho,
        height: [1, 3].includes(o.format) ? baseTamanho * 2 : baseTamanho
      }
    }))
    return a;
  }

  calculaBaseTamanho () {
    let base = (window.outerWidth > 1250) ? 1250 : window.outerWidth
    let columns = Math.floor(base / 250) === 1 ? 2 : Math.floor(base / 250)
    return base / columns
  }

  notificaFavorito () {
    console.log('MenuFiltro.notificaFavorito()', this.state.ofertas)
    const favoritos = this.state.ofertas.filter(o => o.like).map(o => o.id)
    if (!window.localStorage) {
      this.setLocalStorage()
    }
    window.localStorage.setItem('favoritos', JSON.stringify(favoritos))
    if (favoritos.length === 1 && this.state.mostrouModal === false) {
      this.popupFavorito()
    }
  }

  popupFavorito () {
    // TODO: rever modelo de popup de ofertas para desktop
    this.setState(prevState => ({
      mostraModal: !prevState.mostraModal,
      mostrouModal: true
    }))
  }

  scrollBottom () {
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight && !this.notificadoCompleto) {
      this.notificadoCompleto = true //importante para não notificar mais de uma vez
    }
  }

  scrollTop () {
    let offset = (window.innerWidth < 900) ? -120 : -130
    const infoboxHeight = document.getElementById('box-information')
    offset += (infoboxHeight) ? infoboxHeight.offsetHeight : 0

    const navHeight = document.getElementById('menu-filtragem-ofertas');
    offset += (navHeight) ? navHeight.offsetHeight : 0

    const navbar = document.getElementById('header-master')
    offset += (navbar) ? navbar.offsetHeight : 0
    
    window.scrollTo({top: offset, behavior: 'smooth'})
  }

  carregaImagens () {
    document.getElementsByClassName('tamanho-grid')[0].style.display = "block";
  }

  notificaCategoria ( categoria ) {
    const tagManagerArgs = {
      dataLayer: {
        event: "Tabloide",
        eventAction: 'categoria-' + categoria.replace(/\s+/g, '-').toLowerCase(),
        // eventLabel: this.props.ofertas.Numero,
        // tabloideTarifa: this.props.ofertas.Tabloide.LojaTarifa
      },
    }
    TagManager.dataLayer(tagManagerArgs)

    this.setState({
      categoria: categoria,
    })
    this.scrollTop()
  }

  filtraOfertas (oferta, categoria) {
    switch (categoria) {
      case "Todos":
        return true;
      case "Favoritos":
        return oferta.like;
      default:
        return oferta.category.name === categoria;
    };
  }
  
 
  render () {
    const pageUrl = 'https://www.drogariasnovafarma.com.br/ofertas/'
    let current = null
    let ofertas = this.state.ofertas.filter(oferta => (this.filtraOfertas(oferta, this.state.categoria)))

    if (typeof window !== 'undefined') {
      current = JSON.parse(window.localStorage.getItem('currentStore'))
    }

    return (
      <div style={{ overflow: "hidden" }}>
        <div className="mobileSwiper">
          <div id="list-cat" id={this.state.clicado === false ? 'btnanterior' : 'btnproximo'}>
            <Menu notificaCategoria={this.notificaCategoria} />
          </div>
          <div className="d-flex row justify-content-center controllers d-xl-none" style={{ backgroundColor: '#f7f7f7' }}>
            <div className="slide-left">
              <span className="sr-only">
                Anterior
                </span>
              <a onClick={() => this.setState({ clicado: false })} > <img src={imgAnterior} width="20" height="20" title="Anterior" alt="Ir para o lado esquerdo" style={{ transform: 'rotate(180deg)' }} /> </a>
            </div>
            <div className="slide-left">
              <span className="sr-only">
                Proximo
                </span>
              <a onClick={() => this.setState({ clicado: true })} > <img src={imgAnterior} width="20" height="20" title="Proximo" alt="Ir para o lado Direito" /> </a>
            </div>
          </div>
        </div>
        <div className="desktopSwiper">
          <Menu notificaCategoria={this.notificaCategoria} />
        </div>
        <section className="city-place">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>OFERTAS</h2>
                <p>Confira as ofertas válidas aplicadas nos produtos ou até durarem os estoques.</p>
              </div>
            </div>
          </div>
        </section>
        {/* <InformacoesLoja endereco={this.props.endereco}  pageUrl={this.props.pageUrl} mostrar={this.state.site} /> */}
        <span className="mostrando">
          <p>Mostrando: {this.state.categoria}</p>
        </span>
        {
          ofertas.length === 0 && (<div className="d-block mx-auto pt-2" style={{ position: 'relative' }}>
            <p className="text-center h5">NENHUMA OFERTA ENCONTRADA NESTA CATEGORIA!</p>
          </div>
          )
        }
        <Packery
          className="tamanho-grid"
          onImagesLoaded={this.carregaImagens}
          options={MasonryOptions}>
          <div className="grid-sizer"></div>
          {ofertas.map(oferta => {
            return (
              <div key={oferta.id}>
                <Oferta
                  oferta={oferta}
                  handleClick={this.notificaFavorito}
                />
              </div>
            );
          })}
        </Packery>
        <Modal isOpen={this.state.mostraModal} toggle={this.popupFavorito}>
          <ModalHeader toggle={this.popupFavorito} style={{ padding: '0', display: 'inline-block' }}>
            <img src={modalFavorito} style={{ width: '100%', height: '100%' }} />
          </ModalHeader>
        </Modal>
        {current !== null && current.whatsapp !== null && current.whatsapp !== undefined ? <SectionWhatsapp numero={current.whatsapp}/> : ''}
        <br/>
      </div>
    )
  }
}