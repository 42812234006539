/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Swiper from 'react-id-swiper'

/* STYLES */
import './MenuFiltro.css'

/* ICONS */
import modalFavorito from '../../assets/images/modalFavorito.jpg'
import buttonfavorito from '../../assets/icons/buttonfavorito.svg'
// import buttontodos from '../../images/icons/buttontodos.svg'

export default class Menu extends Component {
  constructor (props) {
    super(props)
    this.state = {
      categoria: 'Todos'
    }
  }

  componentWillUnmount () {
    document.removeEventListener('scroll', this.listener)
  }

  setFiltro (categoria) {
    this.setState({
      categoria: categoria
    })
    this.props.notificaCategoria(categoria)
  }

  render () {
    const swiperParams = {
      slidesPerView: 4,
      spaceBetween: 5
    }

    return (
      <Fragment>
        <StaticQuery
          query={graphql`
                  query {
                    Categorias: allCloudOfertasCategoria(sort: {order: ASC, fields: sequence}) {
                      nodes {
                        icon {
                          publicURL
                        }
                        name
                        sequence
                      }
                    }
                  }
                `}
          render={data => (
            <>
              <nav id="menu-filtragem-ofertas" className="pipe-botao d-lg-block d-none">
                <div className="d-flex justify-content-center">
                  <div id="filters" className="slide-center">
                    <div style={{ paddingBottom: '3px', paddingLeft: '45px' }} id="list-cat">
                      {data.Categorias.nodes.map(cat => {
                        return (
                          <button key={cat.id} name="btnFiltro" className={this.state.categoria === cat.name ? 'box-cat button is-checked' : 'box-cat button'} onClick={() => {
                            this.setFiltro(cat.name)
                          }}>
                            <img src={cat.icon.publicURL} id="tamanhobtn" width='50' height='50' alt={`icone ${cat.name}`} />
                            <span className="nome-botao">{cat.name}</span>
                          </button>
                        )
                      })}
                      <button name="btnFiltro2" className={this.state.categoria === 'Favoritos' ? 'box-cat button is-checked' : 'box-cat button'} onClick={() => {
                        this.setFiltro('Favoritos')
                      }}>
                        <img src={buttonfavorito} alt="icone de favorito" id="tamanhobtn" width='50' height='50' />
                        <span className="nome-botao">Favoritos</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="modal fade" id="modalFavorito" role="dialog">
                  <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content" style={{ backgroundColor: '#fff' }}>
                      <div className="modal-header" style={{ backgroundColor: '#fff' }}>
                        <h5 className="modal-title">Favoritos</h5>
                        {/* <button type="button" name="btnFavoritos" className="close" data-dismiss="modal" style={{ color: '#1c2e6d', opacity: 1 }}>&times;</button> */}
                      </div>
                      <img src={modalFavorito} className="img-responsive center-block" title="Mensagem favorito" alt="Mensagem favorito" />
                    </div>
                  </div>
                </div>
              </nav>
              <nav id="nav-ofertas" className="text-center mb-3 d-lg-none">
                <div className="container">
                  <Swiper {...swiperParams}>
                    {data.Categorias.nodes.map(cat => {
                      return (
                        <div key={cat.id}>
                          <button name="btnFiltro" className={this.state.categoria === cat.name ? 'box-cat button is-checked' : 'box-cat button'} onClick={() => {
                            this.setFiltro(cat.name)
                          }}>
                            <img src={cat.icon.publicURL} id="tamanhobtn" width='50' height='50' alt={`icone ${cat.name}`} />
                            <span className="nome-botao">{cat.name}</span>
                          </button>
                        </div>
                      )
                    })}
                    <div>
                      <button name="btnFiltro2" className={this.state.categoria === 'Favoritos' ? 'box-cat button is-checked' : 'box-cat button'} onClick={() => {
                        this.setFiltro('Favoritos')
                      }}>
                        <img src={buttonfavorito} alt="icone de favorito" id="tamanhobtn" width='50' height='50' />
                        <span className="nome-botao">Favoritos</span>
                      </button>
                    </div>
                  </Swiper>
                </div>
              </nav>
            </>
          )}
        />
      </Fragment>
    )
  }
}
