/* eslint-disable react/prop-types */
import React from 'react'
// import { Link } from 'gatsby'
import Whatsapp from '../../assets/icons/bt-solicite.svg'
import './SectionWhatsapp.css'

export function SectionWhatsapp ({ numero }) {
  const regex = /[^0-9]+/g
  return (
    <div className="position-fixed fixed-bottom">
      <div className="bg-Whatsapp-Ofertas">
        <div className="row align-items-center justify-content-end">
          <a href={`https://api.whatsapp.com/send?=pt_BR&phone=55${numero.replace(regex, '')}`} target="_blank" rel="noreferrer noopener">
            <img src={Whatsapp} alt="Icone de Whatsapp"/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default SectionWhatsapp
