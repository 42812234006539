/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react'
import TagManager from 'react-gtm-module'
import * as moment from 'moment'
import { GatsbyImage } from 'gatsby-plugin-image'
import 'moment/locale/pt-br'

/* STYLES */
import './Oferta.css'

export default class Oferta extends Component {
  constructor (props) {
    super(props)
    this.state = {
      oferta: props.oferta
    }

    this.setFavorito = this.setFavorito.bind(this)
  }

  setFavorito (oferta) {
    oferta.like = !oferta.like
    if (oferta.like) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'Tabloide',
          eventAction: 'like'
          // eventLabel: this.props.oferta.Imagens,
          // tabloideTarifa: this.props.tabloide.LojaTarifa
        }
      }
      TagManager.dataLayer(tagManagerArgs)
    }
    console.log('setFavorito', oferta)
    this.setState({
      oferta: oferta
    }, () => {
      this.props.handleClick()
    })
  }

  render () {
    const { oferta } = this.state
    return (
      <Fragment>
        <div className="hvr-grow" style={oferta.style}>
          <GatsbyImage style={{ width: '100%', height: '100%' }}
            image={oferta.image.childImageSharp.gatsbyImageData}
            alt={`Oferta de ${oferta.category.name}`}
            onClick={() => this.setFavorito(oferta)} />
          <button
            type="button"
            style={oferta.category.name === 'Banner' ? { display: 'none' } : estilo}
            aria-label={`Botão para favoritar oferta de ${oferta.category.name}`}
            className={oferta.like ? 'like ativado' : 'like desativado'}
            onClick={() => this.setFavorito(oferta)}
          />
          <div style={validStyle}>
            <small style={oferta.category.name === 'Banner' ? { display: 'none' } : validTextStyle}>Válida até {moment(oferta.finishDate).format('DD/MM/YYYY')}</small>
          </div>
        </div>
      </Fragment>
    )
  }
}

const estilo = {
  backgroundColor: 'transparent',
  outline: 0,
  border: 0
}

const validStyle = {
  zIndex: 1,
  position: 'absolute',
  bottom: '-2px',
  textAlign: 'center',
  width: '100%'
}

const validTextStyle = {
  background: '#fff',
  color: '#333',
  padding: '3px 5px 1px',
  opacity: '0.8'
}
