/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from 'react'
import { Modal } from 'reactstrap'
import { graphql, useStaticQuery } from 'gatsby'
import RenderForm from 'gatsby-plugin-cloudofertas'

/* STYLES */
import './modalOfertas.css'

export default function modalOfertas () {
  const data = useStaticQuery(
    graphql`
      query {
        cloudOfertasForm(name: {eq: "OFERTAS"}) {
          id
          name
          formTypeField {
            name
            type
            required
            style
            length
            label
            formLov {
              formLovData {
                id
                value
                createdDate
              }
            }
          }
        }
      }
    `
  )

  const [modal, setModal] = useState(true)
  const [submitSuccess, setSubmitSuccess] = useState(null)

  const toggle = () => setModal(!modal)

  useEffect(() => {
    if (submitSuccess === true) {
      toggle()

      if (typeof window !== 'undefined') {
        window.localStorage.setItem('submit_ofertas', true)
      }
    }
  }, [submitSuccess])

  return (
    <Fragment>
      <Modal isOpen={modal} toggle={toggle} className='modal-size'>
        <div className="modal-content">
          {!submitSuccess &&
            <>
              <div className="modal-header">Ofertas relâmpago
                <button type="button" className="close" data-dismiss="modal" onClick={() => setModal(false)}>&times;</button>
              </div>
              <div id="carregando" style={{ display: 'none' }}>
              </div>
              <div id="base" className="modal-body">
                <div className="col-md-12">
                  <p>Cadastre-se e receba as ofertas em primeira mão</p>
                </div>
                <RenderForm
                  setSuccessSubmit={setSubmitSuccess}
                  formData={data.cloudOfertasForm}
                  placeholder={true}
                  inputStyle='inputStyle mb-4'
                  btnContainerStyle='btn-custom-warning col-md-5 my-1'
                  btnStyle='btn btn-danger btn-block btn-border'
                  btnName='Cadastre-se'
                />
                {/* <div className="col-md-12 mt-3">
                  <input className="form-check-input ml-1" type="checkbox" value="chkReceber" id="receber" />
                  <label className="form-check-label ml-4" htmlFor="receber">Aceito receber ofertas da Nova Farma</label>
                </div>
                <div className="col-md-12 my-2">
                  <small className="ml-1" style={{ fontSize: '11px' }}>A Nova Farma não comercializa e nem disponibiliza os dados para terceiros.</small>
                </div> */}
              </div>
            </>
          }
        </div>
      </Modal>
    </Fragment>
  )
}
